export const DEFAULT_PHOTO_RATIO = 1
export const DEFAULT_COVER_TEXT = 'Happy Moments'
export const MINIMUM_PHOTOS = 24
export const DEFAULT_MAXIMUM_PHOTOS = 150
export const PAGE_TEXT_MAX_LENGTH = 25

// when the user crosses this threshold, the crop will actually start.
// when he is below this threshold, the crop will not start, enabling a user to easily fit the photo to the page
// During first zoom, while the photo already fits the page, we want to ignore this "resistance"
export const CROP_RESISTANCE_PERCENTAGE = 0.8

export const PHOTO_BOOK_FAQS: { translateKey: string; question: string }[] = [
  {
    translateKey: 'photo-books-size',
    question: 'What sizes do Photo Books come in?',
  },
  {
    translateKey: 'photo-books-customize',
    question: 'How can I customize my Photo Book?',
  },
  {
    translateKey: 'photo-books-delivery',
    question: 'How long does it take to receive my Photo Book?',
  },
  {
    translateKey: 'photo-books-material',
    question: 'What materials are the books made of?',
  },
  {
    translateKey: 'photo-books-discount',
    question:
      'I received a discount for Mixtiles. Can I apply it to my Photo Book purchase?',
  },
  {
    translateKey: 'photo-books-shipment',
    question: 'What countries do you ship to?',
  },
]
