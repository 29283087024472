import { translateManager as t } from './TranslateManager'
import { DISCOUNT_TYPES } from '../stores/promoCodeSlice/promoCodeConsts'
import { calculateDiscountPercent } from '../utils/utils'

interface DiscountTextAndPercentage {
  text: string
  value: number
}

export const getDiscountTextAndPercentage = (
  discount: any,
  type: string,
  productTranslationKey: string,
  originalBundlePrice: number | undefined,
  formatPrice: (value: number) => string
): DiscountTextAndPercentage => {
  switch (type) {
    case DISCOUNT_TYPES.BUYOVER:
      if (discount.metadata.buyOverPrice > 0) {
        return {
          text: t.get(
            'order.checkout.payment_summary_rows.buy_over_percent_off_orders_over_price',
            {
              v1: discount.metadata.buyOverDiscount,
              v2: `${formatPrice(discount.metadata.buyOverPrice)}`,
            }
          ),
          value: discount.metadata.buyOverDiscount,
        }
      } else {
        return {
          text: t.get(
            'order.checkout.payment_summary_rows.discount_percentage',
            {
              v1: discount.metadata.buyOverDiscount,
            }
          ),
          value: discount.metadata.buyOverDiscount,
        }
      }

    case DISCOUNT_TYPES.BUYXGETY:
      const { buy, get } = discount?.metadata
      const percentOff = Math.round((get / (buy + get)) * 100)
      return {
        text: t.get(
          'general.promo_code_success_dialog.save_up_to_message_shortened',
          { v1: discount?.metadata?.percentOff || percentOff.toFixed(2) }
        ),
        value: discount?.metadata?.percentOff || percentOff,
      }
    case DISCOUNT_TYPES.PERCENT:
      if (discount?.metadata?.productType) {
        return {
          text: t.get(
            'general.promo_code_success_dialog.save_message_on_product',
            {
              v1: `${discount?.discount?.percent_off}%`,
              v2: t.get(productTranslationKey),
            }
          ),
          value: discount?.discount?.percent_off,
        }
      }
      return {
        text: t.get(
          'general.promo_code_success_dialog.save_up_to_message_shortened',
          { v1: discount?.discount?.percent_off }
        ),
        value: discount?.discount?.percent_off,
      }
    case DISCOUNT_TYPES.BUNDLE:
    default:
      return {
        text: t.get(
          'general.promo_code_success_dialog.save_up_to_message_shortened',
          {
            v1: `${calculateDiscountPercent(
              discount?.metadata?.bundlePrice,
              originalBundlePrice
            )}`,
          }
        ),
        value: originalBundlePrice
          ? calculateDiscountPercent(
              discount?.metadata?.bundlePrice,
              originalBundlePrice
            )
          : 0,
      }
  }
}

const safeJsonParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (_) {
    return null
  }
}

const isMixCouponIsDefaultOnly = (discount: {
  metadata: { [key: string]: string }
}) => {
  return Object.entries(discount.metadata).every(([key, value]) => {
    return key === 'DEFAULT' || !safeJsonParse(value)?.discountType
  })
}

export const getBestDiscountFromMixType = (
  discount: any,
  productTranslationKey: string,
  pricingItemValue: number,
  formatPrice: (value: number) => string
): DiscountTextAndPercentage => {
  // Get the maximum discount from a MIX type discount
  // Example metadata:
  // {DEFAULT: "{\"buyOverPrice\":10,\"discountType\":\"BUYOVER\",\"buyOverDiscount\":30}",
  // SQUARE_8X8: "{\"buyOverPrice\":10,\"discountType\":\"BUYOVER\",\"buyOverDiscount\":40}",
  // productType: "classic",
  // discountType: "MIX"}
  const discountTextAndPercentage = Object.values(discount.metadata).reduce(
    (acc: DiscountTextAndPercentage, innerDiscountRaw: any) => {
      let innerDiscount
      try {
        innerDiscount = JSON.parse(innerDiscountRaw)
      } catch (_) {
        return acc
      }
      const textAndPercentage = getDiscountTextAndPercentage(
        { metadata: innerDiscount },
        innerDiscount.discountType,
        productTranslationKey,
        pricingItemValue * (innerDiscount.bundleTiles || 1),
        formatPrice
      )

      if (acc.value > textAndPercentage.value) {
        return acc
      } else {
        return textAndPercentage
      }
    },
    { text: '', value: -1 }
  )

  if (isMixCouponIsDefaultOnly(discount)) {
    return discountTextAndPercentage
  } else {
    return {
      text: t.get(
        'general.promo_code_success_dialog.save_up_to_message_shortened',
        { v1: discountTextAndPercentage.value }
      ),
      value: discountTextAndPercentage.value,
    }
  }
}
