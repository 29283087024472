import {
  CLIENT_PROMO_CODE_ERRORS_TYPES,
  DISCOUNT_TYPES,
  DISCOUNT_TYPE_LARGE_ORDER,
} from './promoCodeConsts'
import {
  type Coupon,
  type GiftCardCoupon,
  type TileCredit,
} from './promoCodeSlice.types'
import {
  PRODUCT_TYPES,
  productTypeState,
} from '../../services/ProductTypeState'
import { TILE_SIZES } from '@mixtiles/web-backend-shared'
import { currencyState } from '../../services/CurrencyState'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import { ServerSideAnalyticsPlatform } from '../../services/Analytics/ServerSideAnalytics'

export function getGivenDiscountCouponType(coupon: Coupon | null = null) {
  // On Voucherify there is only 3 types of discount coupons - UNIT, PERCENTS or AMOUNT
  // in order to expend the discount types we add "discountType" field on voucher.metadata
  // this method purpose is to prevent other developers to count on voucher.type which is limited
  if (
    coupon &&
    [DISCOUNT_TYPES.PERCENT, DISCOUNT_TYPES.UNIT].includes(
      coupon?.discount?.type || ''
    )
  ) {
    return coupon.discount?.type || ''
  } else if (coupon?.metadata?.discountType) {
    return coupon.metadata.discountType
  }
  return null
}

export function isValidForProductType(
  discountCoupon: Coupon,
  productType: string
) {
  if (
    productType === PRODUCT_TYPES.PHOTO_BOOK &&
    ![
      DISCOUNT_TYPES.PERCENT,
      DISCOUNT_TYPES.BUYOVER,
      DISCOUNT_TYPES.BUNDLE,
      DISCOUNT_TYPES.MIX,
    ].includes(getGivenDiscountCouponType(discountCoupon) || '')
  ) {
    return false
  }
  if (discountCoupon?.metadata?.productType) {
    if (
      productType === PRODUCT_TYPES.GIFT_CARD &&
      discountCoupon?.metadata?.productType === PRODUCT_TYPES.CLASSIC
    ) {
      // since tiles gift cards are only classic 8X8
      const coupon = discountCoupon as GiftCardCoupon
      return coupon.metadata.tileSizesList?.includes(TILE_SIZES.SQUARE_8X8)
    } else {
      return discountCoupon.metadata.productType === productType
    }
  } else {
    return true
  }
}

// We currently don't support Bundle promo code on Art product
export function isValidCoupon(discountType: string) {
  return !(
    productTypeState.getProductType() === PRODUCT_TYPES.ART &&
    discountType === DISCOUNT_TYPES.BUNDLE
  )
}

export async function callValidateCoupon(code: string) {
  const data = {
    coupon: code,
    requiresOldFreeTiles: true,
    targetCurrency: currencyState.getCurrency(),
  }
  const config = { headers: { supportsBuyxGetyCoupon: true } }
  const response = await mixtilesAxios.post('v4/validateCoupon', data, config)

  return response.data
}

export function isValidPhotoWallsDiscountType(discountType: string) {
  const supportedDiscountTypes = [
    DISCOUNT_TYPES.PERCENT,
    DISCOUNT_TYPES.BUYOVER,
    DISCOUNT_TYPE_LARGE_ORDER,
  ]
  return supportedDiscountTypes.includes(discountType)
}

/**
 * Calculate a single application of EARN_CASH_OVER_AMOUNT discount - if purchased
 * more than the required minimum, receive extra money that can be used for spending.
 *
 * @param priceBeforeDiscount - how many a client has purchased
 * @param buy - minimum price to purchase in order to get the discount
 * @param get - amount of money for spending that a client receives
 * @returns {{isApplied: boolean, usedDiscount: number, unusedDiscount: number, amountForNextDiscount: number}}
 */
function applyOnceEarnCashOverAmountDiscount(
  priceBeforeDiscount: number,
  buy: number,
  get: number
): {
  isApplied: boolean
  usedDiscount: number
  unusedDiscount: number
  amountForNextDiscount: number
} {
  // If discount should be applied
  if (buy <= priceBeforeDiscount) {
    const usedDiscount = Math.min(priceBeforeDiscount - buy, get) // If (priceBeforeDiscount - buy) > get it means the entire discount applied (otherwise only a partial discount and the rest was unused)
    const unusedDiscount = get - usedDiscount
    return {
      isApplied: true,
      usedDiscount,
      unusedDiscount,
      amountForNextDiscount: unusedDiscount + buy,
    }
  } else {
    return {
      isApplied: false,
      usedDiscount: 0,
      unusedDiscount: 0,
      amountForNextDiscount: buy - priceBeforeDiscount,
    }
  }
}

/**
 * Calculate EARN_CASH_OVER_AMOUNT discount - for each X amount get extra Y amount for spending
 * Note: this function also exist in the backend!
 *
 * @param priceBeforeDiscount - price before applying discount
 * @param discountMetadata - discount metadata as defined in voucherify
 * @param allowMultipleApplications - should discount be applied once or multiple times
 */
export function calculateEarnCashOverAmountDiscount(
  priceBeforeDiscount: number,
  discountMetadata: any,
  allowMultipleApplications = false
) {
  // Extract coupon metadata
  const { buy, get, currency = 'USD' } = discountMetadata

  // Initialize discount data params
  let totalDiscount = 0
  let applicationsCounter = 0 // How many times was the discount applied
  let lastDiscount = {
    amountForNextDiscount: buy,
    unusedDiscount: 0,
    usedDiscount: 0,
    isApplied: true,
  }

  // Iterate starting from priceBeforeDiscount and apply multiple discounts until reaching 0
  let amountIterator = priceBeforeDiscount
  while (amountIterator > 0) {
    lastDiscount = applyOnceEarnCashOverAmountDiscount(amountIterator, buy, get)
    totalDiscount += lastDiscount.usedDiscount
    applicationsCounter += lastDiscount.isApplied ? 1 : 0
    if (allowMultipleApplications) {
      amountIterator -= buy + get
    } else {
      break
    }
  }

  return {
    discountPrice: totalDiscount,
    earnCashOverAmount: {
      buy,
      get,
      currency,
      priceBeforeDiscount,
      allowMultipleApplications,
      priceAfterDiscount: priceBeforeDiscount - totalDiscount,
      applicationsCounter,
      amountForNextDiscount: lastDiscount.amountForNextDiscount,
      unusedDiscount: lastDiscount.unusedDiscount,
    },
  }
}

export function handleAlreadyRegisteredError(code: string) {
  analytics.track(EVENT_NAMES.PROMO_CODE_ALREADY_REGISTERED, {
    Code: code,
  })
  return { error: CLIENT_PROMO_CODE_ERRORS_TYPES.ALREADY_REGISTERED }
}

export function trackPromoCodeAdded(
  couponData: Coupon,
  code: string,
  source: string
) {
  const discountType = couponData?.metadata?.discountType

  let type = couponData?.discount?.type
  if (type === 'AMOUNT' && discountType) {
    type = discountType
  }

  analytics.track(
    EVENT_NAMES.PROMO_CODE_ADDED,
    {
      Code: code,
      Type: type,
      Source: source,
      Metadata: couponData.metadata,
    },
    {
      serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Klaviyo],
    }
  )
  analytics.setUserProperties({ 'Used Promo Code': true })
}

export function handleExistingCredit(
  credit: TileCredit | undefined,
  title: string,
  analyticsProperties: any
) {
  if (credit) {
    analytics.track('Coupon already loaded', analyticsProperties)
    return {
      coupon: { discountType: DISCOUNT_TYPES.UNIT, amount: credit.amount },
      couponTitle: title,
    }
  }
}

export function handlePromoCodeLoadResult(
  result: any,
  analyticEvents: any,
  code: string,
  title: string,
  source: string
) {
  if (result.error) {
    analytics.track('Promo Code Automatically Loading Failed', {
      ...analyticEvents,
      error: result.error,
    })
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      `Failed to automatically add promo code: ${code}, error=${result.error}`
    )
  } else if (result.discountType) {
    analytics.track('Promo Code Loaded Automatically', analyticEvents)
    return { coupon: result, couponTitle: title, source }
  } else {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Received invalid promo code (discount type missing)')
  }
}
